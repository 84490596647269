import styled from 'styled-components'

export const Wrapper = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    max-width: 50%;
    transform: translate(-50%, -50%);
    z-index: 11111;
    background-color: #fff;
    @media screen and (max-width: 768px) {
        max-width: 100%;
        width: 100%;
    }
`;

export const Body = styled.div`
    margin: 0 auto;
    padding: 50px;
    img {
        width: auto;
        max-width: 40vw;
        max-height: 70vh;
        @media screen and (max-width: 768px) {
            max-width: 100%;
            max-height: 75%;
        }
    }
`;

export const Backdrop = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
`;

export const CloseBtn = styled.div`
    position: absolute;
    right: 10px;
    top: 10px;
    text-align: center;
    font-size: 1.2em;
    cursor: pointer;
    width: 35px;
    height: 35px;
    border: 2px solid black;
    border-radius: 50%;
    line-height: 1.7em;
    color: #000;
`;
