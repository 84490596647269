import React from "react";
// Global Styles
import { GlobalStyle } from "./GlobalStyle";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// Content
import content from "./content.json";
//Icons

import OfficeIcon from './assets/office.svg';
import PhoneIcon from './assets/phone-call.svg';
import MailIcon from './assets/email.svg';

// Images

import Engineering from './assets/engineering.png'
import Machining from './assets/machining.png'
import Cutting from './assets/plasma-cutting.png'
import Business from './assets/business.png';
import Demontaz from './assets/demontaz.png';
import Metallurgy from './assets/metallurgy.png';
import Photo1 from './assets/photo1.jpg';
import Photo2 from './assets/photo2.jpg';

// Components

import Header from './components/Header';
import Hero from './components/Hero';
import Home from './components/Home';
import Footer from './components/Footer';
import About from "./components/About";
import Offer from "./components/Offer";
import Gallery from "./components/Gallery";
import ArrowTop from "./components/ArrowTop";


const App = () => {
  const footerChildren = [
    {
      "icon" : `${OfficeIcon}`,
      "text" : "Obwodowa 4, 41-100 Siemianowice Slaskie"
    },
    {
      "icon" : `${PhoneIcon}`,
      "text" : "502 434 281"
    },
    {
      "icon" : `${MailIcon}`,
      "text" : "biuro@pphusiemion.pl"
    },
    {
      "icon" : ``,
      "text" : "NIP: 634-007-82-86"
    },    
  ];

  const offerGrid = [
    {
      title : 'Usługi wyburzeniowo – demontażowe',
      link : '',
      background : `${Demontaz}`,
      content : `Realizujemy w kompleksowy sposób wszelkie prace związane z wyburzaniem i demontażem obiektów
      o konstrukcji stalowej, w szczególności hal magazynowych i przemysłowych, wiat, pawilonów,
      zbiorników przemysłowych i MPS, a także elementów linii produkcyjnych i maszyn przemysłowych.
      Posiadamy również możliwość odbioru i transportu odpadów metalowych.`
    },
    {
      title : 'Skup / sprzedaż maszyn i urządzeń',
      link : '',
      background : `${Machining}`,
      content : `Zajmujemy się sprzedażą maszyn i urządzeń przemysłowych, takich jak frezarki CNC, prasy
      hydrauliczne, wózki widłowe, zwyżki magazynowe, elementy automatyki przemysłowej oraz elementy
      całych linii produkcyjnych. Przeprowadzamy także kompleksowy serwis i kontrole maszyn, według
      ściśle określonych standardów oraz norm ISO.`
    },
    {
      title : 'Usługi inżynieryjne',
      link : '',
      background : `${Engineering}`,
      content : `Nasza firma świadczy również usługi inżynierii materiałowej i metalurgicznej, w zakresie kontroli
      jakości wytwarzanych elementów, kontroli procesów technologicznych, pomiarów, analizy, a także
      opracowywania koncepcji maszyn, linii produkcyjnych czy technologicznych, przygotowania i
      planowania automatyzacji procesów w przemyśle, przygotowania komponentów, budowy maszyn,
      urządzeń i linii produkcyjnych oraz szkolenia operatorów maszyn . Nasze usługi są dowolnie
      skalowane, wkomponowane w bieżące potrzeby klienta. Zapewniamy wsparcie na etapie doboru
      komponentów, projektowania, dostosowania technologii oraz wdrażania. Jesteśmy w stanie
      opracować jak najlepszą koncepcję, która spełni swoje zadanie, określone przez zleceniodawcę.
      Przeprowadzamy również badania techniczne, oraz dobieramy odpowiednią technologię i strategię
      dla danego projektu, znacząco odciążając działy planowania i konstrukcji.`
    },
    {
      title : 'Handel wyrobami hutniczymi',
      link : '',
      background : `${Metallurgy}`,
      content : `Zajmujemy się skupem oraz sprzedażą wszelkich wyrobów hutniczych, takich jak np. grodzice
      (larseny), profile stalowe, rury stalowe, pręty żebrowane, siatki zbrojeniowe, blachy stalowe,
      kształtowniki. Jesteśmy w stanie sprostać każdym potrzebom klienta, dobierając elementy niezbędne
      do realizacji projektów lub inwestycji, cechujące się wymaganą przez klienta jakością.`
    },
    {
      title : 'Pośrednictwo handlowe',
      link : '',
      background : `${Business}`,
      content : `Pośredniczymy w realizacji największych projektów, posiadamy kontakty oraz umowy z kontrahentami
      realizującymi zlecenia w zakresie transportu i logistyki, inżynierii maszyn, inżynierii materiałowej,
      budownictwa czy przetwarzania odpadów metalowych. Pośredniczymy również w zakupie i sprzedaży złomu poprodukcyjnego, stalowego i kolorowego, jak i
      również paliw stałych (węgiel kamienny, miał węglowy, koks, pellet drzewny i słonecznikowy)`
    },
    {
      title : 'Przepalanie złomu',
      link : '',
      background : `${Cutting}`,
      content : `W naszej ofercie posiadamy również usługę przepalania / cięcia złomu stalowego i kolorowego o
      większych gabarytach, aby móc zmniejszyć jego objętość, pomagając w ten sposób oszczędzić na
      transporcie i umożliwić jego sprzedaż lub przetop.`
    },
  ];

  const GalleryGridChildren = [`${Photo1}`, `${Photo2}`, `${Machining}`, `${Machining}`, `${Machining}`, `${Machining}`, `${Machining}`, `${Machining}`];

  return (
    <Router>
      <Header links={content.links}/>
      <ArrowTop />
      <Routes>
        <Route 
          path='/'
          element={
            <Home
              heroTitle={content.hero.title}
              heroText={content.hero.text}
              title={content.home[0].title}
              text={content.home[0].text}
              GridChildren={content.offer}
              GalleryGridChildren={GalleryGridChildren}
            />
          }
        />
        <Route 
          path='/home'
          element={
            <Home
              heroTitle={content.hero.title}
              heroText={content.hero.text}
              title={content.home[0].title}
              text={content.home[0].text}
              GridChildren={offerGrid}
              GalleryGridChildren={GalleryGridChildren}
            />
          }
        />
        <Route 
          path='/o nas'
          element={
            <About
              heroTitle={content.hero.title}
              heroText={content.hero.text}
              title={content.about[0].title}
              text={content.about[0].text}
            />
          }
        />
        <Route 
          path='/oferta'
          element={
            <Offer
              heroTitle={content.hero.title}
              heroText={content.hero.text}
              title={content.offer[0].title}
              text={content.offer[0].text}
              offers={offerGrid}
            />
          }
        />
        <Route 
          path='/galeria'
          element={
            <Gallery
              heroTitle={content.hero.title}
              heroText={content.hero.text}
              children={GalleryGridChildren}
            />
          }
        />
      </Routes>
      <Footer children={footerChildren}/>
      <GlobalStyle />
    </Router>
  );
}

export default App;
