import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    color: black;
    padding: 5vh 0;
`;

export const Content = styled.div`
    max-width: 1280px;
    display: flex;
    margin: 0 auto;
    flex-direction: row;
    h1 {
        text-align: left;
        margin: 0 auto;
    }
    div {
        margin: 0 auto;
        padding: 50px;
        @media screen and (max-width: 768px) {
            padding: 30px;
        }
    }
    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
    button {
        margin: 50px 0;
    }
`;

export const Img = styled.img`
    width: 300px;
    height: auto;
`;