import React, {useState} from 'react'
// Components
import Hero from './Hero'
import Modal from './Modal';
import GalleryGrid from './GalleryGrid';

const Gallery = ({heroTitle, heroText, children}) => {
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(0);
    const handleClick = (index) => {
        setActive(index);
        setShow(true);
    };
    const onClose = () => {
        setShow(false);
    };
    return (
        <>
            <Hero 
                title={heroTitle}
                text={heroText}
            />
            <Modal show={show} onClose={onClose}>
                <img src={children[active]} />
            </Modal>
            <GalleryGrid 
                children={
                    children.map((link, index) => (
                        <div   
                            onClick={() => handleClick(index)}
                            key={link.caption}
                        >
                            <img src={link}/>
                        </div>
                    ))
                }
            />
        </>
    );
}

export default Gallery;