import React , {useState} from "react";
// Components
import Hero from './Hero';
import MainSection from './MainSection';
import Grid from './Grid';
import GalleryGrid from './GalleryGrid';
import Thumb from './Thumb';
import Button from './Button';
import Modal from './Modal';
import Slider from './Slider';

const Home = ({heroTitle, heroText, title, text, GridChildren, GalleryGridChildren}) => {
    const [show, setShow] = useState(false);
    const [active, setActive] = useState(0);
    const handleClick = (index) => {
        setActive(index);
        setShow(true);
    };
    const onClose = () => {
        setShow(false);
    };
    return (
        <>
            <Hero
                title={heroTitle}
                text={heroText}
            />
            <MainSection
                title={title}
                text={text}
                link='/o nas'
            />
            <Grid 
                children={GridChildren.map((child) => (
                    <Thumb
                        title={child.title}
                        link={child.link}
                        background={child.background}
                        content={
                            <Button 
                                text='Sprawdz szczegóły'
                                isPrimary={true}
                                link='/oferta'
                            />
                        }
                    />
                ))}
            />
            <Modal show={show} onClose={onClose}>
                <img src={GalleryGridChildren[active]} />
            </Modal>
            <GalleryGrid 
                children={
                    GalleryGridChildren.map((link, index) => (
                        <div   
                            onClick={() => handleClick(index)}
                            key={link.caption}
                        >
                            <img src={link}/>
                        </div>
                    ))
                }
                button={            
                    <Button
                        text='Zobacz wiecej...'
                        isPrimary={false}
                        link='/galeria'
                    />
                }
            />
            
        </>
    );
}

export default Home;