import React from 'react';
import {Wrapper, Content, ChildElement} from './Footer.styles';

const Footer = ({children}) => {
    const getCurrentYear = () => {
        const year = new Date().getFullYear();
        return year.toString();
    }
    return (
        <Wrapper>
            <Content>
                <div>
                    <h2>Kontakt</h2>
                    {children.map(child => (
                        <ChildElement>
                            <img src={child.icon} />
                            <p>{child.text}</p>
                        </ChildElement>
                    ))}
                </div>
                <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927.4549938705445!2d19.041822858648427!3d50.300135973937984!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4716d1b92bd6fe5d%3A0x4fb40e4d27dfb6a7!2sObwodowa%204%2C%2041-100%20Siemianowice%20%C5%9Al%C4%85skie!5e0!3m2!1spl!2spl!4v1705698595209!5m2!1spl!2spl" 
                        style={{ border: 0 }}
                        allowfullscreen="" 
                        loading="lazy" 
                        referrerpolicy="no-referrer-when-downgrade"
                    />
                </div>
            </Content>
            <p>© {getCurrentYear()} Siemion</p>
        </Wrapper>
    );
}

export default Footer;