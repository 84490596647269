import React from 'react';
import {Backdrop, Wrapper, CloseBtn, Body} from './Modal.styles';

const Modal = ({children, show, onClose}) => {
    return (
        show && (
            <>
                <Backdrop />
                <Wrapper>
                    <CloseBtn onClick={onClose}>X</CloseBtn>
                    <Body>
                        {children}
                    </Body>
                </Wrapper>
            </>
        )
    )
}

export default Modal;