import React, {useState, useEffect} from 'react';
import {Link}  from 'react-router-dom';
import LogoImage from '../../assets/logo1.svg';
import { Wrapper, Content, Logo, LinkButton, MobileMenu, Burger, Icon } from './Header.styles';

const Header = ({links}) => {
    const [open, setOpen] = useState(false);
    const [isScrolled, setIsScrolled] = useState(false);

    // useEffect(() => {
    //     window.addEventListener('scroll', () => {
    //         var LastScrollTop = 0;
    //         var st = window.pageYOffset || document.documentElement.scrollTop;
    //         if (st > LastScrollTop) {
    //             navHideStyle = 'translateY(-100%)';
    //             return;
    //         }
    //         lastScrollTop = st;
    //         return;
    //     }, false);
    //     window.removeEventListener('scroll', () => {return;});
    // }, []);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.pageYOffset > 50) {
                setIsScrolled(true);
                return;
            }
            setIsScrolled(false);
            return;
        });
        window.removeEventListener('scroll', () => {return;});
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior : "smooth",
        });
    };

    return (
        <Wrapper>
            <Logo src={LogoImage}/>
            <Content>
                {links.map(link => (
                    <Link to={`/${link.toLowerCase()}`}>
                        <LinkButton onClick={goToTop}>
                            {link}
                            <div />
                        </LinkButton>
                    </Link>
                ))}
            </Content>
            <Burger open={open} onClick={() => setOpen(!open)}>
                <div />
                <div />
                <div />
            </Burger>
            <MobileMenu open={open} setOpen={open}>
                {links.map(link => (
                    <Link to={`/${link.toLowerCase()}`}>
                        {link}
                    </Link>
                ))}
            </MobileMenu>
        </Wrapper>
    )
 }

 export default Header;