import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    background: #222020;
    height: 100%;
    padding: 20px 0; 
    color: white;
    p {
        text-align: center;
    }
`;

export const Content = styled.div`
    max-width: 1280px;
    margin: 0 auto;
    padding: 100px 0;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: left;
    @media screen and (max-width: 768px) {
        padding: 0 50px;
        div {
            padding: 10px;
            margin: 0;
        }
    }
    div {
        padding: 10px;
        margin: 0 auto;
        h2 {
            text-align: center;
        }
        iframe {
            padding: 100px 0 0 0;
            width: 450px;
            height: 400px;
            @media screen and (max-width: 768px) {
                padding: 50px 0 0 0;
                width: 100%;
            }
        }
    }
`;

export const ChildElement = styled.div`
    display: flex;
    flex-direction: row;
    p {
        padding: 0 20px;
    }
`;