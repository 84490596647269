import React, {useState, useEffect} from 'react';
import {Wrapper, Arrow} from './ArrowTop.styles';
import Chevron from '../../assets/chevron.svg';

const ArrowTop = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY > 300) {
                setShowTopBtn(true);
            } else {
                setShowTopBtn(false);
            }
        });
        window.removeEventListener('scroll', () => {return});
    }, []);

    const goToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <Wrapper>
            {""}
                {showTopBtn && (
                    <Arrow
                        src={Chevron}
                        onClick={goToTop} 
                    />
                )}
            {""}
        </Wrapper>
    );
}

export default ArrowTop;