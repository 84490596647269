import styled, {keyframes} from 'styled-components'

const BackgroundAnimationIn = keyframes`
    0% {
        background-size: 100%;
    } 100% {
        background-size: 120%;
    }
`;

const BackgroundAnimationOut = keyframes`
    0% {
        background-size: 120%;
    } 100% {
        background-size: 100%;
    }
`;

export const Wrapper = styled.div`
    width: 100%;
    background-image: url(${props => props.background});
    background-size: cover;
    padding: 20px;
    margin: 0 auto;
    color: var(--white);
    animation: ${BackgroundAnimationOut} .5s ease-out;
    &:hover {
        animation: ${BackgroundAnimationIn} .5s ease-in;
        animation-fill-mode: both;
    }
    &:focus {
        animation: ${BackgroundAnimationIn} .5s ease-in;
        animation-fill-mode: both;
    }
`;

export const Content = styled.div`
    max-width: var(--maxWidth);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 15px;
    margin: 0 auto;
`;