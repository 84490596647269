import React from 'react'
// Styled components
import {Wrapper, Content} from './OfferGrid.styles'

const OfferGrid = ({children}) => (
    <Wrapper>
        <Content>
            {children}
        </Content>
    </Wrapper>
);

export default OfferGrid;