import React from 'react'
// Styled components
import {Wrapper, Content} from './Grid.styles'

const Grid = ({children}) => (
    <Wrapper>
        <Content>
            {children}
        </Content>
    </Wrapper>
);

export default Grid;