import React from "react";
// Logo
import Logo from '../../assets/logo2.svg';
// Styled components
import {Wrapper, Content, Img} from './Section.styles'

const Section = ({title, text}) => (
    <Wrapper>
        <Content>
            <h1>{title}</h1>
        </Content>
        <Content>
            <div>
                <Img src={Logo} />
            </div>
            <div>
                <p>{text}</p>
            </div>
        </Content>
    </Wrapper>
);

export default Section;