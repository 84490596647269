import React, {useState} from 'react';
// Styled components
import { Wrapper, Content } from './GalleryGrid.styles'
// Components
import Button from '../Button';

const GalleryGrid = ({children, button}) => {
    const getImageUrl = (e) => {
        return e.target.getAttribute('src');
    }
    return (
        <Wrapper>
            <h1>Galeria</h1>
            <Content>
                {children}
            </Content>
            {button}
        </Wrapper>
    );
};

export default GalleryGrid;