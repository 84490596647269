import React from "react";
// Styled components
import { Wrapper, Content } from './Hero.styles';
// Components
import Button from '../Button';

const Hero = ({title, text}) => (
    <Wrapper>
        <Content>
            <div>
                <h1>{title}</h1>
                <h3>{text}</h3>
                <Button 
                    text='Sprawdz szczegóły' 
                    link='/oferta' 
                    isPrimary={true}
                />
            </div>
        </Content>
    </Wrapper>
);

export default Hero;