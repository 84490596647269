import styled from 'styled-components'

export const Wrapper = styled.div`
    width: 100%;
    padding: 20px 0;
`;

export const Content = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(30%, 2fr));
    grid-gap: 0;
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
    }
`;