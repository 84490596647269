import styled, {keyframes} from 'styled-components';

const movebtn = keyframes`
    0% {
        transform: translateY(0px);
    }
    50% {
        transform: translateY(30px);
    }
    100% {
        transform: translateY(0px);
    }
`;

export const Wrapper = styled.div`
    position: relative;
    border-radius: 25px;
    height: 50px;
    width: 50px;
`;

export const Arrow = styled.img`
    background-color: white;
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 20;
    height: 60px;
    cursor: pointer;
    padding: 10px;
    border-radius: 30px;
    &:hover {
        animation: ${movebtn} 3s ease-in-out infinite;
        animation-fill-mode: forwards;
    }
`;