import styled, {keyframes} from 'styled-components';

const fade = keyframes`
    from {
        opacity : 0.4;
    }
    to {
        opacity : 1;
    }
`;

export const Wrapper = styled.div`
    position: relative;
    max-width: 500px;
    margin: 0 auto;
`;

export const Content = styled.div`
    position: relative;
    display: none;
    &:active {
        display: block;

    }
`;

export const StyledSlide = styled.div`
    position: relative;
    display: ${({active}) => active ? "block" : "none"};
    animation: ${({active}) => active ? '${fade} 1.5s' : 'none'};
    span {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
    }
    img {
        width: 100%;
    }
`;

export const Navigation = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    div {
        position: absolute;
        top: 50%;
        font-size: 1.5em;
        cursor: pointer;
        transform: translateY(-100%);
        z-index: 10000;
        .next-prev {
            position: absolute;
            top: 50%;
            font-size: 1.5em;
            cursor: pointer;
            transform: translateY(-100%);
            z-index: 10000;
        }
        .next {
            left: 10px;
        }
        .prex {
            right: 10px;
        }
    }
`;
