import styled from 'styled-components';

export const Wrapper = styled.div`
    width: 100%;
    h1 {
        margin: 0 auto;
        text-align: center;
        padding: 20px 0;
    }
`;

export const Content = styled.div`
    max-width: var(--maxWidth);
    margin: 0 auto;
    padding: 50px 25px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 2fr));
    grid-gap: 20px;
    img {
        width: 100%;
        height: 200px;
    }
    @media screen and (max-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(150px, 2fr));
    }
`;

export const ExtendedImg = styled.div`
    display: ${({ open }) => open ? 'block' : 'none'};
    position: fixed; 
    z-index: 1; 
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%; 
    overflow: auto; 
    background-color: rgb(0,0,0); 
    background-color: rgba(0,0,0,0.4);
    padding-top: 60px;
`;
