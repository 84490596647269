import React from 'react'
// Styled components
import {Wrapper, Content} from './Thumb.styles';
// Components
import Button from '../Button';

const Thumb = ({title, link, background, content}) => (
    <Wrapper background={background}>
        <Content background={background}>
            <h2>{title}</h2>
            <div>{content}</div>
        </Content>
    </Wrapper>
);

export default Thumb;