import React from 'react';
// Components
import Hero from './Hero';
import Section from './Section';
import OfferGrid from './OfferGrid';
import Thumb from './Thumb';

const Offer = ({title, text, heroTitle, heroText, offers}) => (
    <>
        <Hero
            title={heroTitle}
            text={heroText}
        />
        <Section
            title={title}
            text={text} 
        />
        <OfferGrid
            children={offers.map(offer => (
                <Thumb
                    title={offer.title}
                    background={offer.background}
                    content={
                        <p>{offer.content}</p>
                    }
                />
            ))}
        />
    </>
);

export default Offer;