import React from "react";
// Components
import Hero from './Hero';
import Section from "./Section";

const About = ({title, text, heroTitle, heroText}) => (
    <>
        <Hero
            title={heroTitle}
            text={heroText}
        />
        <Section
            id='s1'
            title={title}
            text={text}
        />
    </>
);

export default About;