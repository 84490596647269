import React from "react";
import {Wrapper, Content, Img} from './MainSection.styles';
// AOS
import AOS from 'aos';
// Logo
import Logo from '../../assets/logo2.svg';
// Components 
import Button from '../Button';

AOS.init();

const MainSection = ({title, text, link}) => (
    <Wrapper>
        <Content>
            <h1 data-aos="fade-up">{title}</h1>
        </Content>
        <Content>
            <div>
                <Img src={Logo} data-aos="fade-up"/>
            </div>
            <div>
                <p data-aos="fade-up">{text}</p>
                <Button 
                    text='Zobacz wiecej...'
                    isPrimary={false}
                    data-aos="fade-up"
                    link={link}
                />
            </div>
        </Content>
    </Wrapper>
);

export default MainSection;