import styled from 'styled-components'
import Background from '../../assets/hero-image.png'

export const Wrapper = styled.div`
    width: 100%;
    background: url(${Background});
    background-size: cover;
    background-attachment: fixed;
    height: 50%;
    margin: 0 auto;
    @media screen and (max-width: 768px) {
        margin: 0;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 1;
    padding: 15vh 20px;
    text-align: left;
    div {
        margin: 0 auto;
    }
    h1, h3 {
        color: white;
        padding: 20px;
    }
    button {
        margin: 50px 25px;
    }

`;